<template></template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    let ownCustomer = this.$route.query.ownCustomer;
    let status = this.$route.query.status;
    let path = this.$route.query.path;
    let id = this.$route.query.id;
    let query = {};
    if (path == "/project/details") {
      query = {
        id: id,
      };
    } else if (path == "/Pipeline/teamPipeline") {
      query = {};
    } else if (path == "/resume/pending") {
      query = { resumeId: id };
    } else {
      query = {
        ownCustomer: ownCustomer ? ownCustomer : null,
        status: status,
      };
    }
    this.$router.replace({
      path: path,
      query: query,
    });
  },
};
</script>